html,
body,
#root {
	height: 100%;
}

.app {
	height: 100%;
}

.container-fluid,
.row,
.col {
	height: 100%;
	margin: 0;
	padding: 0;
}